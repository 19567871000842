import React from "react";
import "./styles.css";

import iconwpp from "../../assets/iconwpp.png";

import img2 from "../../assets/img-sobre-a-l7.png";

const About = () => {
  return (
    <main id="about">
      <div className="container">
        <div className="column1">
          <h2 className="title">SOBRE A L7</h2>

          <figure className="mobile">
            <img src={img2} alt="Peças" />
          </figure>

          <p className="text">
            L7 Comércio de Peças, há quase 10 anos segmentado o mercado de
            importação de peças automotivas, Com um amplo canal de importação,
            incluindo três polos principais, Estados Unidos, Coreia do Sul e
            Alemanha. Atuamos com estoque em território nacional e operamos
            também com pedidos sob encomenda, assim podemos oferecer uma melhor
            experiência em sua compra. Nossa politica é totalmente guiada pelo
            código do consumidor! Sempre respeitando e cumprindo.
          </p>

          <p className="text2">Ligue agora mesmo e solicite um orçamento:</p>

          <a href="tel:37191827" className="btn">
            <figure>
              <img src={iconwpp} alt="Ícone do Whatsapp" />
            </figure>
            (11) 3719-1827
          </a>
        </div>

        <div className="column2">
          <figure>
            <img src={img2} alt="Peças" />
          </figure>
        </div>
      </div>
    </main>
  );
};

export default About;
