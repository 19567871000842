import React from "react";
import "./styles.css";

import iconwpp from "../../assets/iconwpp.png";

const Header = () => {
  return (
    <header>
      <div id="header"></div>

      <div id="cta">
        <div className="container">
          <p className="text">Ligue agora mesmo e solicite um orçamento:</p>

          <a href="tel:37191827" className="btn">
            <figure>
              <img src={iconwpp} alt="Ícone do Whatsapp" />
            </figure>
            (11) 3719-1827
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
