import React from "react";
import "./styles.css";

import img1 from "../../assets/forma-pagamento.png";
import img2 from "../../assets/frete.png";
import img3 from "../../assets/horario-atendimento.png";
import iconwpp from "../../assets/iconwpp.png";
import iconwpp2 from "../../assets/iconwpp2.png";

const Others = () => {
  return (
    <section id="others">
      <div className="other1">
        <div className="banner"></div>
        <div className="container">
          <div className="column1">
            <p>Ligue agora e solicite um orçamento:</p>
            <a href="tel:37191827" className="btn tel">
              <figure>
                <img src={iconwpp} alt="Ícone do Whatsapp" />
              </figure>
              (11) 3719-1827
            </a>
          </div>

          <div className="column2">
            <p>Solicite um orçamento via Whatsapp: </p>
            <a
              href="http://wa.me/+5511934111522?text=Olá,%20L7%20Peças%20América!%20Gostaria%20de%20comprar%20peças"
              target="_blank"
              rel="noreferrer"
              className="btn wpp"
            >
              <figure>
                <img src={iconwpp2} alt="Ícone do Whatsapp" />
              </figure>
              (11) 93411-1522
            </a>
          </div>
        </div>
      </div>

      <div className="other2">
        <div className="container">
          <figure>
            <img src={img1} alt="Formas de pagamento" />
          </figure>

          <figure>
            <img src={img2} alt="Fretes grátis para todo o Brasil" />
          </figure>

          <figure>
            <img src={img3} alt="Horário de Atendimento" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Others;
